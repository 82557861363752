/*------------------------------------*\
    Services
\*------------------------------------*/

.services {}

.services__item {
    text-align: center;
    margin-bottom: 90px;
}

.services__icon {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    background-color: $color-primary;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: drop-shadow(1px 1px 3px rgba($color-black, 0.3));

        g {
            fill: #fff;
        }
    }
}

.services__title {
    margin-top: 30px;
    color: $color-black;
    line-height: 1.2;
}
