/*------------------------------------*\
    Headings
\*------------------------------------*/
.heading {}

.heading__title {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 0px;
    line-height: 1.4;

    &::after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 70px;
        height: 2px;
        content: ' ';
        background-color: $color-primary;
    }
}

.heading--main {
    text-align: center;
    margin-bottom: 90px;

    @include breakpoint(xs) {
        margin-bottom: 30px;
    }

    @include breakpoint(xs-max) {
        margin-bottom: 30px;
    }

    .heading__title {
        text-transform: uppercase;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 30px;
        color: $color-black;
    }

    .heading__title::after {
        left: 50%;
        transform: translate(-50%, 0%);
    }
}

.heading--caption {
    text-transform: uppercase;

    .heading__title {
        font-weight: 700;
        padding-bottom: 0px;
    }

    .heading__title::after {
        display: none;
    }
}
