/*------------------------------------*\
    Products
\*------------------------------------*/

.products {}

.products__item {
    text-align: center;
    margin-bottom: 90px;

    @include breakpoint(xs) {
        margin-bottom: 30px;
    }

    @include breakpoint(xs-max) {
        margin-bottom: 30px;
    }
}

.products__icon {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba($color-black, 0.15);
    margin: 0px 30px;

    @include breakpoint(sm) {
        margin: 0px 15px;
    }

    @include breakpoint(xs) {
        margin: 0px;
    }

    @include breakpoint(xs-max) {
        margin: 0px;
    }
}
