/*------------------------------------*\
   03. Helpers
\*------------------------------------*/

.h--inverse {
    color: $color-white;
}

.h--relative {
    position: relative;
}

.h--clickable {
    cursor: pointer;
}

/* Font styles */

.h--uppercase {
    text-transform: uppercase;
}

.h--lowercase {
    text-transform: lowercase;
}

/* Font alignment */
.h--align-left {
    text-align: left;
}

.h--align-center {
    text-align: center;
}

.h--align-right {
    text-align: right;
}

.h--align-justify {
    text-align: justify;
}

/* Colors */

.h--color-primary {
    color: $color-primary;
}

.h--color-secondary {
    color: $color-secondary;
}

.h--color-gray {
    color: $color-gray;
}

.h--color-gray02 {
    color: $color-gray02;
}

.h--color-gray03 {
    color: $color-gray03;
}

/* Margins */
@for $i from 0 through 60 {
    .h--mt#{$i} {
        margin-top: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--mb#{$i} {
        margin-bottom: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--ml#{$i} {
        margin-left: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--mr#{$i} {
        margin-right: 0px + $i;
    }
}

/* Paddings */
@for $i from 0 through 60 {
    .h--pt#{$i} {
        padding-top: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--pb#{$i} {
        padding-bottom: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--pl#{$i} {
        padding-left: 0px + $i;
    }
}

@for $i from 0 through 60 {
    .h--pr#{$i} {
        padding-right: 0px + $i;
    }
}
