/*------------------------------------*\
    Page Builder
\*------------------------------------*/

.content {}

.content__part {
    padding-bottom: 120px;
    padding-top: 120px;
}

.content__text {
    color: rgba($color-black, 0.8);

    h3 {
        margin-bottom: 20px;
    }

    a {
        color: rgba($color-black, 0.8);

        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
    }
}

.content__text--medium {
    font-size: 18px;

    @include breakpoint(xs) {
        font-size: 16px;
    }

    @include breakpoint(xs-max) {
        font-size: 14px;
    }
}

.content__text--large {
    font-size: 22px;

    @include breakpoint(xs) {
        font-size: 16px;
    }

    @include breakpoint(xs-max) {
        font-size: 14px;
    }
}

.content__contact-image {
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba($color-black, 0.15);

    @include breakpoint(xs) {
        margin-bottom: 30px;
    }

    @include breakpoint(xs-max) {
        margin-bottom: 30px;
    }
}
