/*------------------------------------*\
    Footer
\*------------------------------------*/

.footer {
    color: rgba($color-white, 0.6);
    background-color: $color-gray04;

    font-size: 14px;
}

.footer__main {
    padding-top: 60px;
    padding-bottom: 90px;

    @include breakpoint(xs) {
        padding-bottom: 0px;
    }

    @include breakpoint(xs-max) {
        padding-bottom: 0px;
    }
}

.footer__column {
    @include breakpoint(xs) {
        margin-bottom: 30px;
    }

    @include breakpoint(xs-max) {
        margin-bottom: 30px;
    }
    
    &:first-child {
        .footer__text {
            text-align: justify;
        }
    }
}

.footer__title {
    text-transform: uppercase;
    color: $color-white;
    margin-bottom: 30px;
}

.footer__text {}

.footer__button-group {
    margin-top: 30px;
}

.footer__nav {
    padding: 0px;
    list-style-type: none;
}

.footer__nav-item {
    margin-bottom: 5px;
}

.footer__nav-link {
    text-transform: uppercase;
    color: rgba($color-white, 0.6);
    font-weight: bold;
    transition: 0.2s ease-in-out;
    padding: 5px 0px;
    display: inline-block;

    &:hover {
        padding-left: 10px;
        text-decoration: none;
    }
}

.footer__copyright {
    padding: 10px 0px;
    text-align: center;
    background-color: $color-gray05;
    color: $color-white;
}
