/*------------------------------------*\
    Variables and Bootstrap overrides
\*------------------------------------*/

/* Colors */
$color-default: #a3a3a3;

/* Stack colors like this depending on shades */
$color-primary: #ffde16;
$color-secondary: #FF6530;
$color-accent: #FFC930;

$color-supplement: rgb(0, 153, 102);

$color-gray: #f7f7f7;
$color-gray02: #e9e9e9;
$color-gray03: #BEBEBE;
$color-gray04: #2e2e2e;
$color-gray05: #151515;

$color-white: #ffffff;
$color-black: #000000;

$color-success: #50CE52;
$color-error: #EF5350;

/* Social networks colors */
$color-facebook: #3b5998;

/* Font Weight */
$weight-light: 100;
$weight-normal: 400;
$weight-medium: 600;
$weight-bold: 800;
$weight-extra-bold: 900;

/* Font families */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&subset=latin-ext');

$font-primary: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
$font-secondary: "Yantramanav", "Helvetica Neue", Helvetica, sans-serif;

/* Breakpoints */
@mixin breakpoint($point) {
    @if $point == xs-max {
        @media (max-width: 480px) {
            @content;
        }
    }

    @else if $point == xs {
        @media (min-width: 481px) and (max-width: 767px) {
            @content;
        }
    }

    @else if $point == sm {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }

    @else if $point == md {
        @media (min-width: 992px) and (max-width: 1280px) {
            @content;
        }
    }

    @else if $point == lg {
        @media (min-width: 1281px) {
            @content;
        }
    }
}
