/*------------------------------------*\
    Jumbotron
\*------------------------------------*/

.jumbotron {
    position: relative;
    z-index: 1;
    font-size: 24px;
    background-size: cover;
    background-position: center;
    color: $color-white;
    background-color: $color-black;
    height: 70vh;
    display: flex;
    align-items: center;
    background-image: url('../images/cnc-miling.jpg');

    @include breakpoint(sm) {
        background-image: url('../images/cnc-miling--tablet.jpg');
    }

    @include breakpoint(xs) {
        padding: 60px 0px;
        height: auto;
        background-image: url('../images/cnc-miling--mobile.jpg');
    }

    @include breakpoint(xs-max) {
        padding: 60px 0px;
        height: auto;
        background-image: url('../images/cnc-miling--mobile.jpg');
    }
}

.jumbotron__title {
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 30px;
    padding-bottom: 24px;
    text-transform: uppercase;
    line-height: 1.2;
    position: relative;

    &::after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 70px;
        height: 1px;
        background-color: $color-primary;
        content: " ";
    }
}

.jumbotron--subpage {
    height: 240px;
    min-height: 0px;
    text-align: center;

    .jumbotron__title {
        margin: 0px;
        padding: 0px;
    }

    .jumbotron__title::after {
        display: none;
    }
}
