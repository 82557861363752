/*------------------------------------*\
    Contact Widget
\*------------------------------------*/

.contact-widget {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include breakpoint(xs) {
        justify-content: flex-start;
    }

    @include breakpoint(xs-max) {
        justify-content: flex-start;
    }
}

.contact-widget__item {
    margin-right: 30px;

    &:last-of-type {
        margin-right: 0px;
    }

    @include breakpoint(xs) {
        margin-right: 15px;
    }

    @include breakpoint(xs-max) {
        margin-right: 15px;
    }
}

.contact-widget__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;

    img {
        width: 100%;
    }

    @include breakpoint(xs) {
        width: 30px;
    }

    @include breakpoint(xs-max) {
        width: 30px;
    }
}

.contact-widget__text {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    color: rgba($color-black, 0.4);
    font-weight: bold;
    line-height: 1;

    @include breakpoint(xs) {
        font-size: 12px;
    }

    @include breakpoint(xs-max) {
        font-size: 12px;
    }

    a {
        color: rgba($color-black, 0.4);
        transition: 0.2s ease-in-out;

        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
    }
}
