/*------------------------------------*\
    Buttons
\*------------------------------------*/

/*
 * Button Groups
 */

.button-group {
    text-align: left;

    .button {
        margin-right: 15px;

        &:last-of-type {
            margin-right: 0px;
        }
    }
}

.button-group--align-right {
    text-align: right;
}

.button-group--align-center {
    text-align: center;
}

.button-group--tabs {
    .button {
        margin-right: 0px;
        border-radius: 0px;
        padding: 0px 30px;

        &:first-of-type {
            border-radius: 3px 3px 0px 0px;
        }

        &:last-of-type {
            border-radius: 0px 0px 3px 3px;
        }
    }
}

/*
 * Buttons
 */

.button {
    font-size: 16px;
    display: inline-block;
    padding: 0px 25px;
    transition: .2s ease-in-out;
    text-align: center;
    vertical-align: middle;
    color: $color-black;
    border-width: 0;
    outline: none;
    background-color: $color-primary;
    box-shadow: none;
    height: 43px;
    line-height: 43px;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        text-decoration: none;
        background-color: darken($color-primary, 10);
        color: $color-white;
    }
}

.button__icon {
    margin-right: 5px;
    font-size: 24px;
    display: inline-block;
    vertical-align: text-bottom;
}

/*
 * Button Modifiers
 */

.button--secondary {
    background-color: $color-secondary;

    &:hover {
        background-color: darken($color-secondary, 10);
    }
}

.button--neutral {
    border: 1px solid rgba($color-black, 0.2);
    background-color: $color-white;
    color: $color-default;

    &:hover {
        background-color: $color-white;
        color: $color-secondary;
    }
}

.button--outline {
    color: $color-gray02;
    border: 1px solid $color-gray02;
    color: $color-default;
    background-color: transparent;

    &:hover {
        color: $color-default;
        background-color: $color-gray02;
    }
}

.button--link {
    height: auto;
    background-color: transparent;
    color: $color-secondary;
    padding-top: 0px;
    padding-bottom: 0px;

    &:hover {
        background: transparent;
        text-decoration: underline;
    }
}

.button--transparent {
    background-color: transparent;
    color: $color-secondary;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid rgba($color-black, 0.2);
        background-color: $color-white;
        color: $color-default;
    }
}

.button--success {
    background-color: $color-success;
    color: $color-white;

    &:hover {
        color: $color-white;
        background-color: darken($color-success, 10);
    }
}

.button--uppercase {
    text-transform: uppercase;
}

/* Inverse buttons */

.button--outline.button--inverse {
    color: $color-white;
    border: 1px solid $color-white;
    background-color: transparent;

    &:hover {
        color: $color-default;
        background-color: $color-white;
    }
}

.button--transparent.button--inverse {
    color: $color-white;

    &:hover {
        color: $color-default;
    }
}

/* Social */
.button--facebook,
.button--google {
    .button__icon {
        margin-right: 15px;
    }
}

.button--facebook {
    background-color: $color-facebook;
}

.button--google {
    background-color: $color-white;
    color: $color-default;
}

/* Sizes */
.button--small {
    height: auto;
    font-size: 16px;
    padding: 5px 20px;
}

.button--large {
    padding: 10px 40px;
    height: auto;
}

.button--block {
    display: block;
    width: 100%;
}

/*
 * Button States
 */

/* Disabled */
.button.is-disabled {
    opacity: 0.7;

    &:hover {
        background-color: $color-primary;
        color: $color-white;
    }
}

.button--secondary.is-disabled {
    &:hover {
        background-color: $color-secondary;
        color: $color-white;
    }
}

.button--outline.button--inverse.is-disabled {
    &:hover {
        color: $color-white;
        border: 1px solid $color-white;
        background-color: transparent;
    }
}

.button--neutral.is-disabled {
    &:hover {
        border: 1px solid rgba($color-black, 0.2);
        background-color: $color-white;
        color: $color-default;
    }
}

.button--outline.is-disabled {
    &:hover {
        color: $color-gray02;
        border: 1px solid $color-gray02;
        color: $color-default;
        background-color: transparent;
    }
}

.button--link.is-disabled {
    &:hover {
        height: auto;
        background-color: transparent;
        color: $color-secondary;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.button--transparent.is-disabled {
    &:hover {
        background-color: transparent;
        color: $color-secondary;
    }
}
