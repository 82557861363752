/*------------------------------------*\
    Cookies
\*------------------------------------*/
/* To be used with cookieCuttr by Chris Wharton (http://cookiecuttr.com) */

.cc-cookies {
    position: fixed;
    width: 20%;
    right: 0;
    bottom: 0;
    padding: 15px 15px;
    background: $color-black;
    color: #fff;
    font-size: 13px;
    font-weight: lighter;
    z-index: 99999;
    text-align: center;
    color: #fff;

    @include breakpoint(xs-max) {
        width: 100%;
        position: fixed;
        bottom: 0px;
        right: 0px;
    }
}

.cc-cookies a, .cc-cookies a:hover {
    color: #fff;
    text-decoration: underline;
}

.cc-cookies a:hover {
    text-decoration: none;
}

.cc-overlay {
    height: 100%;
    padding-top: 25%;
}

.cc-cookies-error {
    float: left;
    width: 90%;
    text-align: center;
    margin: 1em 0 2em 0;
    background: #fff;
    padding: 2em 5%;
    border: 1px solid #ccc;
    font-size: 18px;
    color: #333;
}

.cc-cookies a.cc-cookie-accept, .cc-cookies-error a.cc-cookie-accept, .cc-cookies a.cc-cookie-decline, .cc-cookies-error a.cc-cookie-decline, .cc-cookies a.cc-cookie-reset {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.cc-cookies a.cc-cookie-decline, .cc-cookies-error a.cc-cookie-decline {
    background: #af3b3b;
    margin-left: 0.5em;
}

.cc-cookies a.cc-cookie-reset {
    background: #f15b00;
}

.cc-cookies a:hover.cc-cookie-accept, .cc-cookies-error a:hover.cc-cookie-accept, .cc-cookies a:hover.cc-cookie-decline, .cc-cookies-error a:hover.cc-cookie-decline, .cc-cookies a:hover.cc-cookie-reset {
    background: #000;
    -o-transition: background 0.5s ease-in;
    -webkit-transition: background 0.25s ease-in;
    -moz-transition: background 0.25s ease-in;
}

.cc-cookies-error a.cc-cookie-accept, .cc-cookies-error a.cc-cookie-decline {
    display: block;
    margin-top: 1em;
}

.cc-cookies.cc-discreet {
    width: auto;
    padding: 0.5em 1em;
    left: auto;
    top: auto;
}

.cc-cookies.cc-discreet a.cc-cookie-reset {
    background: none;
    text-shadow: none;
    padding: 0;
    text-decoration: underline;
}

.cc-cookies.cc-discreet a:hover.cc-cookie-reset {
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .cc-cookies a.cc-cookie-accept, .cc-cookies a.cc-cookie-decline, .cc-cookies a.cc-cookie-reset {
        display: block;
    }
}