/*------------------------------------*\
    Base styles
\*------------------------------------*/

html {
    font-size: 16px;
}

body {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    color: $color-default;
    min-height: 100vh;
}

h1 {
    font-size: 48px;

    @include breakpoint(xs) {
        font-size: 36px;
    }

    @include breakpoint(xs-max) {
        font-size: 28px;
    }
}

h2 {
    font-size: 42px;

    @include breakpoint(xs) {
        font-size: 32px;
    }

    @include breakpoint(xs-max) {
        font-size: 24px;
    }
}

h3 {
    font-size: 24px;

    @include breakpoint(xs) {
        font-size: 18px;
    }

    @include breakpoint(xs-max) {
        font-size: 16px;
    }
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

a {
    color: $color-primary;

    &:hover {
        color: darken($color-primary, 10);
    }
}

img {
    max-width: 100%;
}

/* Class that will blink - Intended to be put on static elements that needs to be dynamic */
.hardcoded {
    color: #f00 !important;
    animation: hardcode 1s infinite;
}

@keyframes hardcode {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
