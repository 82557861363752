/*------------------------------------*\
    Dividers
\*------------------------------------*/

.divider {
    border-top: 1px solid $color-gray02;
    margin-bottom: 15px;
    margin-top: 15px;
}


.divider--large-top {
    margin-top: 60px;
}