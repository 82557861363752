/*------------------------------------*\
    Header
\*------------------------------------*/

.header {
    background-color: $color-white;
}

.header__lang {
    background-color: $color-gray;
}

.header__main {
    padding: 15px 0px;
    //box-shadow: 0px 1px 10px rgba($color-black, 0.3);
}

.header__logo {
    height: 105px;
    display: block;

    @include breakpoint(xs-max) {
        height: auto;
        max-width: 180px;
        margin-bottom: 15px;
    }

    svg {
        height: 100%;
        max-width: 100%;
    }
}

.header__nav {
    background-color: $color-gray04;
}

/*------------------------------------*\
    Navigation
\*------------------------------------*/

.main-nav {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    @include breakpoint(xs) {
        display: none;
    }

    @include breakpoint(xs-max) {
        display: none;
    }
}

.main-nav__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding-right: 15px;
    margin-right: 15px;

    @include breakpoint(xs) {
        display: block;

        &:last-of-type {
            margin-bottom: 15px;
        }
    }

    @include breakpoint(xs-max) {
        display: block;

        &:last-of-type {
            margin-bottom: 15px;
        }
    }

    &::after {
        position: absolute;
        top: 50%;
        height: 20px;
        transform: translateY(-50%);
        right: 0px;
        background-color: rgba($color-white, 0.15);
        width: 1px;
        content: " ";

        @include breakpoint(xs) {
            top: auto;
            bottom: 0px;
            width: 100%;
            height: 1px;
        }

        @include breakpoint(xs-max) {
            top: auto;
            bottom: 0px;
            width: 100%;
            height: 1px;
        }
    }

    &.is-active {
        .main-nav__link {
            color: $color-primary;
        }
    }
}

.main-nav__link {
    color: $color-white;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 0px;
    display: block;
    line-height: 1;
    transition: 0.15s ease-in-out;

    &:hover {
        color: $color-primary;
        text-decoration: none;
    }
}

.main-nav-toggle {
    display: inline-block;
    padding: 17px;
    padding-right: 0px;
    float: right;
    display: none;
    position: relative;
    z-index: 15;

    &:hover {
        cursor: pointer;
    }

    @include breakpoint(xs) {
        display: block;
    }

    @include breakpoint(xs-max) {
        display: block;
    }
}

.main-nav-toggle__line {
    height: 2px;
    width: 23px;
    background-color: #ffffff;
    display: block;
    margin: 3px 0px;
    transition: 0.2s ease-in-out;
}

// Toggle
.header.is-opened {
    .main-nav-toggle__line {
        &:nth-child(1) {
            transform: rotate(45deg) translate(3px, 5px);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg) translate(2px, -4px);
        }
    }
}

/*------------------------------------*\
    Language
\*------------------------------------*/

.lang-nav {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    text-align: right;
}

.lang-nav__item {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.lang-nav__link {
    display: block;
    color: $color-black;
    font-size: 12px;
    padding: 10px 0px;

    &:hover {
        color: $color-black;
    }
}

/* Bootstrap reset */
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
}
