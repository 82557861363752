/*------------------------------------*\
    Machines
\*------------------------------------*/

.machines {}

.machines__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
}

.machines__count {
    width: 60px;
    height: 60px;
    flex-basis: 60px;
    background-color: $color-primary;
    color: $color-black;
    text-align: center;
    border-radius: 100%;
    font-size: 28px;
    line-height: 58px;
    font-weight: bold;
}

.machines__title {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 30px;
    padding-right: 0px;
    color: $color-black;
    line-height: 1.2;
    width: calc(100% - 90px);
    flex-basis: calc(100% - 90px)
}
